import React,{useState} from 'react'
import {ErrorGeneral,llamadaApiExt} from '../funciones/ClienteAxios';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import {InputLabel,FormControl,Select as MaterialSelect  ,Box ,    
        LinearProgress,TextField,FormLabel, Typography
    } from '@material-ui/core';
import { toast } from 'react-toastify';     

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '20ch',
        }, 
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        Textnumero: {
            width: 5+"px",
        },
        formControlFull: { width:5+"rem"
        },
        margin: {
            margin: theme.spacing(1),
        },
    },
    }));
function ListaTipo({listaTodo,setListaTodo}) {
    let history = useHistory();
    const classes = useStyles();
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    let UsuId =localStorage.getItem('UsuId') ;      
    let UsuTipo =localStorage.getItem('Tipo') ;          
    const source = axios.CancelToken.source();    
    const [mensaje, setMensaje] = useState('');
    const [aviso, setAviso] =  useState(false);    
    const [espera,setEspera]= useState(false);        
    const [listZona,setListZona]= useState([])
    const [listReg,setListReg]= useState([])
    const [listMun,setListMun]= useState([])
    const [listSecc,setListSecc]= useState([])
    const [listProm,setListProm]= useState([])
    const [zonaSelect,setZonaSelect]= useState("");
    const [regSelect,setRegSelect]= useState("");
    const [munSelect,setMunSelect]= useState("");
    const [seccSelect,setSeccSelect]= useState("");
    const [promSelect,setPromSelect]= useState("");
    const[municipios,setMunicipios]= useState([]);
    const[secciones,setSecciones]= useState([]);
    React.useEffect(()=>{
        let bandera=true

        llamaLocacion(0,1,setMunicipios) 

        return ()=> {
            source.cancel();
        }     
    },[])

    const llamaLocacion = (auxLlave,nivel,setLista) => {             
        let data = qs.stringify({
            Llave:auxLlave,     
            Tipo:nivel,       
            idDispositivo: auxDispV,
        });
        let url = "corcholata-locacion-contacto";
        function respuesta(auxiliar){                  
            
            if (auxiliar[0].Id !== -1 ) 
            {   auxiliar.splice(0,0,{leyenda:"TODO"})   
                console.log(auxiliar);             
                setLista(auxiliar)                
            }              
        }
        llamadaApiExt(data, url, respuesta,ErrorGeneral,setEspera,history);
    };

    function listarMun(auxLista) {
        let respuesta=[]
        auxLista.forEach((element)=>{
            
            let busq = element.MunId ? respuesta.findIndex((indice)=>indice.MunId===element.MunId):0
            if ( busq === -1) 
            {   respuesta.push({MunId:element.MunId,MunNom:element.MunNom,Cel:element.Cel})    
            }            
        })
        respuesta.splice(0,0,{leyenda:"TODO"})         
        console.log(respuesta);     
        
        setMunicipios( respuesta)
    }

    function listarSecc(auxLista) {
        let respuesta=[]
        auxLista.forEach((element)=>{
                        
            let busq = element.SecId ? respuesta.findIndex((indice)=>indice.SecId===element.SecId):0
            if ( busq === -1) 
            {   respuesta.push({SecId:element.SecId,SecNom:element.SecNom,Cel:element.Cel})    
            }            
        })
        respuesta.splice(0,0,{leyenda:"TODO"})        
        console.log(respuesta);      
        setListaTodo(auxLista) 
        setSecciones( respuesta)
    }

    const llamaDetList = (auxLlave,nivel,setLista) => {             
        let data = qs.stringify({
            Llave:auxLlave,     
            Tipo:nivel,       
            idDispositivo: auxDispV,
        });
        let url = "corcholata-contacto-cel";
        function respuesta(auxiliar){                  
            
            if (auxiliar[0].Id !== -1 ) 
            {    auxiliar.splice(0,0,{leyenda:"TODO"})
                console.log(auxiliar);               
               /*  if (nivel===1) {
                    listarMun(auxiliar)
                }
                if (nivel===2) {
                    listarSecc(auxiliar)
                } */
                setLista(auxiliar)                
            }              
        }
        llamadaApiExt(data, url, respuesta,ErrorGeneral,setEspera,history);
    };

    const onChangeZona=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setZonaSelect(e)             
            if (e.UsrId) 
            {   llamaDetList(e.UsrId,3,setListReg)   
                setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listZona)
            }            
        }
        else
        {   setZonaSelect([])                
        }        
        setListReg([])      
        setListMun([])  
        setListSecc([])
        setListProm([])
        setRegSelect([])      
        setMunSelect([])   
        setSeccSelect([]) 
        setPromSelect([])
    }

    const onChangeReg=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setRegSelect(e)             
            if (e.UsrId) 
            {   llamaDetList(e.UsrId,4,setListMun)                 
                setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listReg)
            }
        }
        else
        {   setRegSelect([])                
        }          
        setListMun([])  
        setListSecc([])
        setListProm([])             
        setMunSelect([])   
        setSeccSelect([])
        setPromSelect([])
    }

    const onChangeMun=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setMunSelect(e)             
            if (e.Nom) 
            {   llamaLocacion(e.Id,2,setSecciones)                 
                //setListaTodo(e)                  
            }
            else
            {   console.log(e);
                llamaDetList(1,1, setListaTodo)                                                                    
                //setListaTodo(listMun)
            }             
        }
        else
        {   setMunSelect([])                
        }                  
        setListSecc([])
        setListProm([])
        setSeccSelect([])
        setPromSelect([])
    }

    const onChangeSecc=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setSeccSelect(e)   
            //console.log(e);                                       
            if (e.Nom) 
            {   llamaDetList(e.Id,3, setListaTodo)                 
                //setListaTodo(e)                  
            }
            else
            {   //console.log(e);
               // setListaTodo(listSecc)
                llamaDetList(munSelect.Id,2, setListaTodo)       
            } 
        }
        else
        {   setSeccSelect([])                
        }  
        setListProm([])          
        setPromSelect([])                    
    }

    const onChangeProm=(e)=>{        
        if (e!== null && e!== undefined) 
        {   setPromSelect(e)     
            if (e.UsrId) 
            {   setListaTodo(e)                  
            }
            else
            {   console.log(e);
                setListaTodo(listMun)
            }         
        }
        else
        {   setPromSelect([])                
        }                          
    }

    const opcionLablZon=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.ZonNom}`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablReg=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.RegNom}`    
        }
        else
        {   if (option.leyenda )
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablMun=(option)=>{
        let respuesta=""
        if (option.Nom) 
        {   respuesta = `${option.Nom} (${option.Total})`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablSec=(option)=>{
        let respuesta=""
        if (option.Nom ) 
        {   respuesta = `${option.Nom} (${option.Total})`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

    const opcionLablProm=(option)=>{
        let respuesta=""
        if (option.Cel ) 
        {   respuesta = `${option.Cel} - ${option.Cargo} - ${option.ProNom}`    
        }
        else
        {   if (option.leyenda ) 
            {   respuesta = `${option.leyenda} `    
            }
        }
        return respuesta
    }

  return (
    <Box>
        {listZona.length!==0 ?    
            <Autocomplete
                id="zona-list" size='small'  options={listZona} value={zonaSelect} name="zonaSelect"
                onChange={(e,option)=>onChangeZona(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablZon} style={{ width: 40+'rem' ,marginTop:.8+'rem'}}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Zona" variant="outlined" 
                    />
                )}
                renderOption={(option) => 
                    { let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.ZonNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>}
                }
            />    
        :null}
        {listReg.length!==0 ?  
           <Autocomplete
                id="reg-list" size='small'  options={listReg} value={regSelect} name="regSelect"
                onChange={(e,option)=>onChangeReg(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablReg} style={{ width: 40+'rem',marginTop:.8+'rem' }}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Region" variant="outlined" 
                    />
                )}
                renderOption={(option) => 
                    { let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.RegNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>}
                }
            />   
        :null}
        {municipios.length!==0 ?              
            <Autocomplete
                id="mun-list" size='small'  options={municipios} value={munSelect} name="munSelect"
                onChange={(e,option)=>onChangeMun(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablMun} style={{ width: 40+'rem',marginTop:.8+'rem'}}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Municipio" variant="outlined" 
                    />
                )}
                renderOption={(option) => { 
                    let auxtext=option.leyenda ? option.leyenda:`${option.Nom} (${option.Total})`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>
                }}
            />             
        :null}
        {secciones.length!==0 ?              
            <Autocomplete
                id="secc-list" size='small'  options={secciones} value={seccSelect} name="seccSelect"
                onChange={(e,option)=>onChangeSecc(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablSec} style={{ width: 40+'rem',marginTop:.8+'rem' }}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Sección" variant="outlined" 
                    />
                )}
                renderOption={(option) =>{ 
                    let auxtext=option.leyenda ? option.leyenda:`${option.Nom} (${option.Total})`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>
                }}
            />             
        :null}
        {listProm.length!==0 ?  
            listProm[0].Cargo!==""?
            <Autocomplete
                id="prom-list" size='small'  options={listProm} value={promSelect} name="promSelect"
                onChange={(e,option)=>onChangeProm(option)} noOptionsText={"Sin coincidencias"}         
                getOptionLabel={opcionLablProm} style={{ width: 40+'rem' ,marginTop:.8+'rem'}}            
                renderInput={(params) => (
                    <TextField {...params} size='small'
                        label="Lista Promotor" variant="outlined" 
                    />
                )}
                renderOption={(option) => { 
                    let auxtext=option.leyenda ? option.leyenda:`${option.Cel} - ${option.Cargo} - ${option.ProNom}`                      
                    return<React.Fragment>
                        {auxtext}
                    </React.Fragment>
                }}
            /> 
            :<Typography style={{marginTop:.5+'rem'}} > Sin promotores asignados</Typography>
        :null}
    </Box>
  )
}

export default ListaTipo